import "bootstrap/dist/css/bootstrap.min.css";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import "rsuite/Loader/styles/index.css";
import usePageTracking from "./hooks/pageTracking";
import "./App.css";
import { AppRoutes } from "./Routes/routes";
import DefaultLayout from "./layouts/defaultLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { fetchCartData } from "./store/cartReducer";
import { fetchSiteData } from "./store/siteReducer";
import UseGeneral from "./hooks/useGeneral";
import { pagesName } from "./data/pagesName";
import { change, change2 } from "./store/languageReducer";
function App() {
  const { language } = UseGeneral();
  // const s=useSelector(s=>console.log(s));
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const location = useLocation();
  //   const fetchDataHandler = () => {
  //     dispatch(fetchData());
  //   };
  usePageTracking();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    setTimeout(() => {

      document.title =
        language == "ar"
          ? pagesName?.filter(
              (item) =>
                location?.pathname?.toLowerCase() ==
                  item?.route?.toLowerCase() ||
                location?.pathname
                  ?.toLowerCase()
                  ?.includes(item?.route?.toLowerCase())
            )[1]?.name_ar
          : pagesName?.filter(
              (item) =>
                location?.pathname?.toLowerCase() ==
                  item?.route?.toLowerCase() ||
                location?.pathname
                  ?.toLowerCase()
                  ?.includes(item?.route?.toLowerCase())
            )[1]?.name_en;
      if (location?.pathname == "/ar"||location?.pathname == "/en"||location?.pathname =="/") {
        document.title = language == "ar" ? " الرئيسية" : "Home";
      }
    }, 200);
  }, [language, location?.pathname]);

  let localLang=localStorage.getItem('ouroubaLanguage') || "en"


  useEffect(() => {

    let linkArr=window.location.href.split('/')
    let lastElementLang=linkArr[linkArr.length-1][0]+linkArr[linkArr.length-1][1]
    let lastElement=linkArr[linkArr.length-1]
    console.log(lastElementLang)
    if(lastElementLang=='ar'||lastElementLang=='en'){
      if(localLang!=lastElementLang){

        let locQuesMark=lastElement.indexOf('?');
        console.log(locQuesMark,"locQuesMarklocQuesMark")
        if(locQuesMark!=-1){
          let afterQues=lastElement.substring(locQuesMark+1,lastElement.length)
          // alert(afterQues)
          localStorage.setItem('greenTreesLanguage',lastElementLang)
        const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
        // window.location.reload()
        // Ensure no double slashes in the final URL
        const newPath = `/${currentPath}/${lastElementLang}?${afterQues}`.replace(/\/\//, '/');
        navigate(newPath, { replace: true });
        dispatch(change2(lastElementLang))
        }
        else {
          localStorage.setItem('greenTreesLanguage',lastElementLang)
        const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
        // window.location.reload()
        // Ensure no double slashes in the final URL
        const newPath = `/${currentPath}/${lastElementLang}`.replace(/\/\//, '/');
        navigate(newPath, { replace: true });
        dispatch(change2(lastElementLang))
        }




          // localStorage.setItem('greenTreesLanguage',localLang);
          // const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
          // // window.location.reload()
          // // Ensure no double slashes in the final URL
          // let lastElement=linkArr[linkArr.length-1];
          // let locQuesMark=lastElement.indexOf('?');
          // console.log(locQuesMark)
          // if(locQuesMark!=-1){
          //   let afterQues=lastElement.substring(locQuesMark+1,lastElement.length)
          //   console.log(afterQues)
          //   const newPath = `/${currentPath}/${localLang}?${afterQues}`.replace(/\/\//, '/');
          //   dispatch(change2(lastElementLang))
          //   navigate(newPath, { replace: true });
          // }
          // else {
          //   console.log('rerekl')
          //   let afterQues=lastElement.substring(locQuesMark+1,lastElement.length)
          //   console.log(afterQues)
          //   const newPath = `/${currentPath}/${localLang}`.replace(/\/\//, '/');
          //   dispatch(change2(lastElementLang))
          //   navigate(newPath, { replace: true });
          // }

      }

    }
    else {
      console.log(location.pathname,"navigatenavigatenavigate")
      const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
      // window.location.reload()
      // Ensure no double slashes in the final URL
      let lastElement=linkArr[linkArr.length-1];
      let locQuesMark=lastElement.indexOf('?');
      console.log(locQuesMark)
      if(locQuesMark!=-1){
        let afterQues=lastElement.substring(locQuesMark+1,lastElement.length)
        console.log(afterQues)
        const newPath = `/${currentPath}/${localLang}?${afterQues}`.replace(/\/\//, '/');
        navigate(newPath, { replace: true });
      }
      else {
        let afterQues=lastElement.substring(locQuesMark+1,lastElement.length)
        console.log(afterQues)
        const newPath = `/${currentPath}/${localLang}`.replace(/\/\//, '/');
        navigate(newPath, { replace: true });
      }
    }
    setTimeout(() => {
      scrollToTop();
    }, 100);

    return


    // if(localLang==null){
    //   if(languageInURL==null){

    //     localStorage.setItem('ouroubaLanguage',"ar")
    //     const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
    //     // window.location.reload()
    //     // Ensure no double slashes in the final URL
    //     const newPath = `${currentPath}/${"ar"}`.replace(/\/\//, '/');

    //     dispatch(change2("ar"))
    //     // Navigate to the new path with the selected language appended
    //     navigate(newPath, { replace: true });
    //   }
    //   else {
    //     localStorage.setItem('ouroubaLanguage',languageInURL)
    //     const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
    //     // window.location.reload()
    //     // Ensure no double slashes in the final URL
    //     const newPath = `${currentPath}/${languageInURL}`.replace(/\/\//, '/');
    //     dispatch(change2(languageInURL))
    //     // Navigate to the new path with the selected language appended
    //     navigate(newPath, { replace: true });
    //   }
    // }
    // else {
    //   if(languageInURL==null){
    //     const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
    //     // window.location.reload()
    //     // Ensure no double slashes in the final URL
    //     const newPath = `${currentPath}/${localLang}`.replace(/\/\//, '/');
    //     dispatch(change2(localLang))
    //     // Navigate to the new path with the selected language appended
    //     navigate(newPath, { replace: true });
    //   }
    //   else {
    //     if(languageInURL==localLang){
    //       const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
    //       // window.location.reload()
    //       // Ensure no double slashes in the final URL
    //       const newPath = `${currentPath}/${languageInURL}`.replace(/\/\//, '/');
    //       dispatch(change2(languageInURL))
    //       // Navigate to the new path with the selected language appended
    //       navigate(newPath, { replace: true });
    //     }
    //     else {
    //       const currentPath = location.pathname.replace(/\/(ar|en)$/, '').replace(/\/$/, '');
    //       // window.location.reload()
    //       // Ensure no double slashes in the final URL
    //       const newPath = `${currentPath}/${languageInURL}`.replace(/\/\//, '/');
    //       localStorage.setItem('ouroubaLanguage',languageInURL)
    //       // Navigate to the new path with the selected language appended
    //       dispatch(change2(languageInURL))
    //       navigate(newPath, { replace: true });
    //     }
    //   }
    // }

  }, [location?.pathname,localLang]);


  // useEffect(()=>{

  //   window.location.reload()
  // },[localLang])
  // window.location.reload()

  useEffect(() => {
    dispatch(fetchSiteData());
  }, []);
  const fetchDataHandler = () => {
    dispatch(fetchCartData());
  };
  useEffect(() => {
    fetchDataHandler();
  }, []);
  return (
    <DefaultLayout>
      {<AppRoutes />}
      <Toaster containerClassName="toastCon" />
    </DefaultLayout>
  );
}

export default App;
