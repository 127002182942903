import React from 'react';
import UseGeneral from '../../../hooks/useGeneral';
import './exportbanner.css';
import { useSelector } from 'react-redux';
const ExportBanner = () => {
  const { language } = UseGeneral();
  const siteData = useSelector((state) => state?.site?.siteData);
  console.log(siteData)
  return (
    <div className="export_banner rowDiv">
      <div className="left">
        <img
          src={require("../../../assets/plane.png")}
          className="largeScreen"
          alt=""
        />
        <div className="smallscreen exportscreenPlane">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="82"
            height="61"
            viewBox="0 0 82 61"
            fill="none"
          >
            <path
              d="M15.0422 14.5645C15.4552 14.1771 16.2367 14.2323 16.8058 14.0998C16.9369 14.0484 17.1415 14.3294 17.0065 14.4274C16.5043 14.6691 15.8526 14.8791 15.2429 14.8921C15.0934 14.8604 14.9596 14.6419 15.0422 14.5645Z"
              fill="#FFF100"
            />
            <path
              d="M19.2691 10.6287C19.2669 11.2452 18.7765 11.6736 18.5259 12.1473C18.481 12.2759 18.1882 12.1384 18.2066 11.9637C18.3507 11.4288 18.6017 10.8318 18.9497 10.4451C19.0566 10.383 19.2695 10.5054 19.2691 10.6287Z"
              fill="#FFF100"
            />
            <path
              d="M21.2188 9.67496C20.8472 10.1355 20.2253 10.0607 19.7546 10.2136C19.644 10.2738 19.5087 9.93418 19.6272 9.818C20.0555 9.5333 20.6005 9.28835 21.0915 9.2794C21.2083 9.31913 21.2932 9.58284 21.2188 9.67496Z"
              fill="#FFF100"
            />
            <path
              d="M17.4847 13.9518C18.0282 13.7488 18.1768 13.156 18.17 12.6213C18.1768 12.5057 17.954 12.4192 17.9473 12.5349C17.8932 12.8095 17.8038 13.0409 17.6772 13.2578C17.5523 13.4459 17.3919 13.5906 17.267 13.7786C17.1877 13.8365 17.3682 13.9953 17.4847 13.9518Z"
              fill="#FFF100"
            />
            <path
              d="M23.1097 10.0402C22.8171 9.41104 22.2735 9.3145 21.8134 9.39771C21.7122 9.40577 21.6858 9.68748 21.787 9.67943C22.0357 9.70623 22.2547 9.78201 22.4693 9.90473C22.6585 10.0295 22.8181 10.2032 23.0074 10.3279C23.0745 10.4158 23.1725 10.175 23.1097 10.0402Z"
              fill="#FFF100"
            />
            <path
              d="M12.4832 14.8206C12.723 14.6987 13.0823 14.6893 13.4175 14.7267C13.7319 14.7184 14.1 14.778 14.4233 14.8388C14.546 14.8587 14.5965 15.0191 14.4738 14.9992C14.1386 14.9619 13.8242 14.9701 13.5427 15.0007C13.2283 15.0089 12.9556 15.1086 12.6412 15.1168C12.4945 15.1439 12.3573 14.8933 12.4832 14.8206Z"
              fill="#FFF100"
            />
            <path
              d="M11.0649 15.9875C11.2247 15.9362 11.408 16.1903 11.2481 16.2416C11.1213 16.3155 10.9494 16.3907 10.8226 16.4646C10.6716 16.5863 10.62 16.776 10.448 16.8512C10.3785 16.9001 10.213 16.7868 10.2163 16.6925C10.184 16.3167 10.733 16.1139 11.0649 15.9875Z"
              fill="#FFF100"
            />
            <path
              d="M8.63979 18.4625C8.76144 18.2202 8.8831 17.9779 9.05967 17.8055C9.15404 17.7072 9.35527 17.8441 9.29444 17.9652C9.21849 18.2061 9.12116 18.3999 9.01167 18.618C8.90218 18.836 8.85976 19.0997 8.64965 19.2493C8.56747 19.3234 8.3327 19.1636 8.3478 19.0439C8.378 18.8044 8.50887 18.6334 8.63979 18.4625Z"
              fill="#FFF100"
            />
            <path
              d="M8.32541 21.1494C8.33655 21.4775 8.3477 21.8055 8.42491 22.1787C8.48116 22.5056 8.60351 22.8776 8.51554 23.1379C8.48807 23.2793 8.24472 23.1449 8.16983 23.0296C7.99023 22.6825 8.00326 22.3068 7.99218 21.9789C7.94795 21.6282 7.96989 21.3228 8.02482 21.04C8.06439 20.8748 8.35294 21.0081 8.32541 21.1494Z"
              fill="#FFF100"
            />
            <path
              d="M8.83778 23.9852C8.95093 24.2826 9.05201 24.6036 9.17717 24.8776C9.23975 25.0146 9.33521 25.1739 9.44271 25.3097C9.5502 25.4456 9.61597 25.4901 9.66652 25.6505C9.69622 25.7653 9.58233 25.8145 9.47164 25.7712C9.15159 25.6179 8.99676 25.229 8.8716 24.955C8.73761 24.6118 8.6814 24.2898 8.63724 23.9443C8.6404 23.8517 8.80809 23.8704 8.83778 23.9852Z"
              fill="#FFF100"
            />
            <path
              d="M11.0394 26.2216C11.1558 26.4236 11.3168 26.6242 11.5136 26.7554C11.6776 26.8647 11.8863 26.9727 11.9698 27.1528C12.0116 27.2429 11.9758 27.3124 11.8864 27.315C11.4393 27.328 10.9502 26.5665 10.8487 26.25C10.8188 26.1367 10.9648 26.1096 11.0394 26.2216Z"
              fill="#FFF100"
            />
            <path
              d="M13.3879 27.017C13.6624 27.1694 13.9816 27.3204 14.28 27.4263C14.6231 27.531 14.8379 27.4563 15.0976 27.3802C15.2438 27.3532 15.4287 27.5081 15.3899 27.6693C15.1003 28.3182 13.9248 27.6881 13.3043 27.1795C13.2178 27.0905 13.2895 26.9511 13.3879 27.017Z"
              fill="#FFF100"
            />
            <path
              d="M15.8324 26.4578C16.2802 26.2797 16.3958 25.8741 16.6896 25.5582C16.7713 25.485 16.9707 25.6216 16.9554 25.7403C16.8488 26.2167 16.4643 26.5351 15.9622 26.6436C15.8593 26.6701 15.7506 26.531 15.8324 26.4578Z"
              fill="#FFF100"
            />
            <path
              d="M17.8176 22.1854C18.208 23.0005 18.0375 23.689 17.7256 24.2162C17.6772 24.3118 17.4452 24.1529 17.4937 24.0573C17.7151 23.5325 17.7708 22.8942 17.5461 22.1927C17.462 22.0062 17.7335 21.9989 17.8176 22.1854Z"
              fill="#FFF100"
            />
            <path
              d="M16.817 19.1886C17.3007 19.7996 17.5567 20.509 17.714 21.1517C17.7645 21.312 17.4863 21.2501 17.4686 21.1119C17.2543 20.4939 16.9054 19.8793 16.6013 19.2636C16.5179 19.081 16.7096 19.0529 16.817 19.1886Z"
              fill="#FFF100"
            />
            <path
              d="M15.6181 16.5171C16.0776 16.8246 16.4593 17.4549 16.6352 17.885C16.7187 18.0658 16.4501 18.0734 16.3666 17.8926C16.2623 17.6665 16.1131 17.4418 15.9521 17.2403C15.791 17.0388 15.5852 16.8385 15.436 16.6138C15.3615 16.5014 15.5197 16.4512 15.6181 16.5171Z"
              fill="#FFF100"
            />
            <path
              d="M25.5966 12.5581C26.0561 12.8657 26.4379 13.4959 26.6137 13.926C26.6972 14.1068 26.4286 14.1144 26.3451 13.9336C26.2408 13.7076 26.0917 13.4828 25.9306 13.2813C25.7695 13.0798 25.5637 12.8796 25.4145 12.6548C25.34 12.5424 25.4982 12.4922 25.5966 12.5581Z"
              fill="#FFF100"
            />
            <path
              d="M23.6142 10.3091C24.0737 10.6166 24.4554 11.2469 24.6313 11.677C24.7148 11.8578 24.4462 11.8654 24.3627 11.6845C24.2584 11.4585 24.1092 11.2338 23.9482 11.0323C23.7871 10.8308 23.5812 10.6305 23.4321 10.4058C23.3576 10.2934 23.5158 10.2432 23.6142 10.3091Z"
              fill="#FFF100"
            />
            <path
              d="M11.7752 12.6576C12.037 12.8302 12.2634 13.0719 12.5134 13.2675C12.8171 13.5292 13.1326 13.768 13.38 14.0542C13.52 14.2082 13.372 14.3263 13.2202 14.1954C12.9492 13.9552 12.6337 13.7165 12.351 13.4993C12.101 13.3037 11.7737 13.088 11.6036 12.8219C11.4635 12.6679 11.6116 12.5498 11.7752 12.6576Z"
              fill="#FFF100"
            />
            <path
              d="M9.00616 10.5316C9.46637 10.7414 9.95426 11.1536 10.4002 11.4769C10.5637 11.5841 10.4816 11.7451 10.3181 11.6379C9.84867 11.3606 9.32308 11.1079 8.90057 10.7386C8.74889 10.6084 8.83101 10.4474 9.00616 10.5316Z"
              fill="#FFF100"
            />
            <path
              d="M27.2232 14.8686C27.5402 15.3104 27.783 15.9376 28.0313 16.4661C28.1278 16.6504 27.9737 16.7375 27.8772 16.5532C27.5849 16.0496 27.2293 15.534 27.0251 14.9806C26.9506 14.7839 27.1047 14.6968 27.2232 14.8686Z"
              fill="#FFF100"
            />
            <path
              d="M28.7105 17.4931C29.0275 17.935 29.2704 18.5621 29.5186 19.0906C29.6151 19.2749 29.461 19.362 29.3645 19.1777C29.0722 18.6741 28.7166 18.1586 28.5124 17.6052C28.4379 17.4085 28.592 17.3213 28.7105 17.4931Z"
              fill="#FFF100"
            />
            <path
              d="M29.9971 20.1647C30.1415 20.3454 30.1596 20.6256 30.2142 20.8568C30.2422 20.9362 30.0751 20.9967 30.0471 20.9173C29.9448 20.7034 29.7891 20.491 29.7823 20.2425C29.7781 20.1545 29.9452 20.0939 29.9971 20.1647Z"
              fill="#FFF100"
            />
            <path
              d="M6.01448 8.61483C6.2577 8.7607 6.4457 8.96024 6.65215 9.14193C6.8585 9.32354 7.09444 9.53118 7.28243 9.73071C7.42986 9.86046 7.28259 10.0036 7.13516 9.87381C6.92876 9.69216 6.69282 9.48452 6.46806 9.3208C6.26166 9.13916 6.03685 8.9754 5.86722 8.75793C5.74927 8.65413 5.85972 8.54681 6.01448 8.61483Z"
              fill="#FFF100"
            />
            <path
              d="M34.853 37.94C36.1176 37.4329 37.5363 38.2695 38.7827 38.4852C39.0956 38.4874 39.1374 39.3139 38.7593 39.4144C37.5051 39.5085 36.0057 39.3942 34.8296 38.8692C34.5844 38.6609 34.6 38.0414 34.853 37.94Z"
              fill="#FFF100"
            />
            <path
              d="M48.2664 36.0979C47.2683 36.9735 45.7645 37.243 44.5852 37.7431C44.3033 37.895 44.0399 37.4963 44.3523 37.2604C45.454 36.5993 46.8329 35.924 48.0335 35.6151C48.3109 35.6009 48.4661 35.9227 48.2664 36.0979Z"
              fill="#FFF100"
            />
            <path
              d="M53.2127 34.133C52.2145 35.0086 50.7108 35.2781 49.5315 35.7783C49.2496 35.9301 48.9862 35.5314 49.2986 35.2956C50.4002 34.6345 51.7792 33.9592 52.9798 33.6503C53.2572 33.636 53.4124 33.9578 53.2127 34.133Z"
              fill="#FFF100"
            />
            <path
              d="M58.6639 32.0715C57.6657 32.9471 56.162 33.2166 54.9827 33.7168C54.7008 33.8686 54.4373 33.4699 54.7497 33.2341C55.8514 32.573 57.2304 31.8977 58.431 31.5888C58.7083 31.5745 58.8636 31.8963 58.6639 32.0715Z"
              fill="#FFF100"
            />
            <path
              d="M63.711 30.8088C62.593 31.5056 61.0884 31.4921 59.859 31.7741C59.5596 31.8742 59.3725 31.4202 59.7159 31.2408C60.8975 30.781 62.3506 30.3597 63.5678 30.2755C63.8393 30.3139 63.9347 30.6694 63.711 30.8088Z"
              fill="#FFF100"
            />
            <path
              d="M69.168 30.0814C67.958 30.609 66.4825 30.2804 65.2297 30.3318C64.9195 30.3787 64.8087 29.8438 65.1747 29.7196C66.4092 29.4641 67.904 29.3071 69.1131 29.4691C69.3735 29.5677 69.4101 29.9758 69.168 30.0814Z"
              fill="#FFF100"
            />
            <path
              d="M74.6163 29.7782C73.4062 30.3058 71.9307 29.9772 70.6779 30.0286C70.3678 30.0755 70.2569 29.5406 70.623 29.4164C71.8575 29.1608 73.3523 29.0039 74.5613 29.1659C74.8218 29.2645 74.8584 29.6726 74.6163 29.7782Z"
              fill="#FFF100"
            />
            <path
              d="M79.956 30.3482C78.715 30.676 77.3106 30.1199 76.0812 29.9727C75.773 29.9698 75.7246 29.4264 76.0961 29.362C77.3305 29.3056 78.8081 29.3872 79.9709 29.7374C80.2143 29.8755 80.2043 30.2826 79.956 30.3482Z"
              fill="#FFF100"
            />
            <path
              d="M40.0168 39.4336C41.2521 39.4782 42.4555 38.6673 43.3059 37.8436C43.5035 37.6708 43.2705 37.3926 43.0729 37.5653C42.54 37.9508 42.0177 38.2467 41.4566 38.4963C40.9449 38.7026 40.4437 38.8194 39.932 39.0258C39.7061 39.0626 39.7521 39.424 40.0168 39.4336Z"
              fill="#FFF100"
            />
            <path
              d="M30.4319 35.8907C31.0351 35.8708 31.6644 36.1118 32.1782 36.409C32.7288 36.6199 33.2635 36.9753 33.7196 37.3006C33.8978 37.419 33.7297 37.706 33.5515 37.5875C33.0377 37.2904 32.4871 37.0795 31.9574 36.9268C31.4068 36.7159 30.7826 36.6776 30.232 36.4667C29.9384 36.4045 30.1014 35.9148 30.4319 35.8907Z"
              fill="#FFF100"
            />
            <path
              d="M26.3724 35.8377C26.7277 35.8746 26.6363 36.3752 26.281 36.3381C25.9462 36.3576 25.5321 36.3471 25.1973 36.3665C24.745 36.4389 24.3542 36.6804 23.9402 36.6699C23.7434 36.6929 23.6408 36.411 23.7965 36.2753C24.3402 35.7024 25.6029 35.7903 26.3724 35.8377Z"
              fill="#FFF100"
            />
            <path
              d="M18.4479 37.1946C19.0404 36.9462 19.633 36.6978 20.2051 36.5867C20.5209 36.5187 20.6376 36.8564 20.3413 36.9806C19.8275 37.2605 19.3535 37.4592 18.8202 37.6828C18.2869 37.9064 17.7926 38.2426 17.2009 38.2974C16.9446 38.3404 16.8084 37.9465 17.0258 37.7909C17.4609 37.4795 17.9544 37.337 18.4479 37.1946Z"
              fill="#FFF100"
            />
            <path
              d="M13.6642 40.6998C13.1612 41.1947 12.6584 41.6896 12.1968 42.2979C11.7729 42.8227 11.3906 43.4608 10.8256 43.7857C10.5534 43.9765 10.3501 43.6067 10.4052 43.383C10.6497 42.742 11.2698 42.1932 11.7728 41.6984C12.2551 41.1467 12.7787 40.7086 13.323 40.327C13.6537 40.1094 13.9364 40.5091 13.6642 40.6998Z"
              fill="#FFF100"
            />
            <path
              d="M9.94713 45.3605C9.66866 45.9062 9.33226 46.4801 9.11143 46.9977C9.00101 47.2565 8.91153 47.5736 8.87978 47.8625C8.84803 48.1515 8.88989 48.2678 8.72173 48.5548C8.59039 48.7554 8.31759 48.7513 8.19707 48.6048C7.89313 48.1372 8.2454 47.4189 8.46623 46.9012C8.78161 46.2692 9.19648 45.7256 9.66918 45.1538C9.82152 45.0112 10.0785 45.1599 9.94713 45.3605Z"
              fill="#FFF100"
            />
            <path
              d="M9.97649 50.791C9.85128 51.2055 9.80371 51.6512 9.9273 52.0109C10.0303 52.3106 10.2109 52.6416 10.0651 52.9962C9.99221 53.1735 9.82106 53.2595 9.66576 53.197C8.88937 52.8845 9.27603 51.2839 9.60894 50.6946C9.73877 50.4886 10.0289 50.5536 9.97649 50.791Z"
              fill="#FFF100"
            />
            <path
              d="M12.4876 54.4554C12.7105 54.9033 13.0113 55.3819 13.3486 55.7725C13.7636 56.194 14.2466 56.2318 14.8075 56.3005C15.0983 56.3647 15.1655 56.7506 14.8432 56.9819C13.3206 57.8143 12.3304 55.9327 12.0875 54.6558C12.0824 54.4486 12.4255 54.2768 12.4876 54.4554Z"
              fill="#FFF100"
            />
            <path
              d="M17.3805 56.0172C18.4269 56.0789 19.2715 55.5795 20.2761 55.3349C20.5321 55.2878 20.6532 55.6245 20.4375 55.7838C19.495 56.3908 18.3321 56.5749 17.3044 56.3751C17.087 56.3403 17.1243 56.0642 17.3805 56.0172Z"
              fill="#FFF100"
            />
            <path
              d="M27.4771 51.5604C26.8442 53.0191 25.4548 53.9 24.0811 54.4471C23.8457 54.552 23.7028 54.1582 23.9382 54.0532C25.1534 53.4461 26.2666 52.5576 27.0014 51.3803C27.1552 51.0501 27.6308 51.2303 27.4771 51.5604Z"
              fill="#FFF100"
            />
            <path
              d="M30.575 46.2439C30.4323 47.5454 29.7443 48.8387 28.9934 49.9573C28.8254 50.2445 28.4481 49.9492 28.637 49.7203C29.2515 48.5996 29.6301 47.3886 30.0873 46.2076C30.2345 45.8622 30.6068 45.9548 30.575 46.2439Z"
              fill="#FFF100"
            />
            <path
              d="M32.8814 41.1828C33.1718 42.0152 32.8174 43.3098 32.4322 44.1309C32.2864 44.4831 31.8188 44.298 31.9646 43.9457C32.1468 43.5055 32.2511 43.0343 32.2983 42.5918C32.3454 42.1492 32.3146 41.6758 32.4189 41.2047C32.471 40.9691 32.8192 41.0045 32.8814 41.1828Z"
              fill="#FFF100"
            />
            <path
              d="M32.7922 31.7882C32.9525 32.2779 32.9413 32.8532 33.0444 33.3715C33.1304 34.0407 33.2736 34.6813 33.2252 35.3464C33.211 35.7095 32.7708 35.7867 32.7278 35.4521C32.6618 34.8441 32.5186 34.2034 32.3955 33.6239C32.2923 33.1057 32.092 32.4935 32.2376 31.9224C32.2518 31.5593 32.6921 31.4822 32.7922 31.7882Z"
              fill="#FFF100"
            />
            <path
              d="M31.6828 25.9746C32.1124 26.6924 32.2553 27.7644 32.474 28.6566C32.5728 28.9651 32.1691 29.1623 32.0703 28.8537C31.7363 28.0179 31.2671 27.1766 31.1637 26.2281C31.1227 25.8914 31.5264 25.6943 31.6828 25.9746Z"
              fill="#FFF100"
            />
            <path
              d="M30.6302 21.8822C30.8509 22.2861 30.8878 22.7554 30.9859 23.203C31.0841 23.6504 31.1963 24.162 31.2332 24.6313C31.3033 24.9509 30.8132 25.1255 30.7431 24.8059C30.645 24.3584 30.5328 23.8468 30.3734 23.4212C30.2752 22.9737 30.1158 22.548 30.1402 22.0568C30.0841 21.8011 30.4516 21.6701 30.6302 21.8822Z"
              fill="#FFF100"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
          >
            <g clip-path="url(#clip0_1494_7301)">
              <path
                d="M39.8957 41.8884C42.4111 43.5875 48.9014 45.0111 50.6269 42.49C52.3524 39.969 48.5482 34.5021 46.0185 32.7779C45.6408 32.5161 44.4269 31.7 42.649 30.528L41.8114 2.89416L38.7624 0.851946L31.1716 23.1698C25.8622 19.8729 19.9506 16.3583 14.8399 13.6941L14.7218 7.43005L11.828 6.13166L9.3431 11.059C5.59606 9.45209 2.07178 8.26796 1.34253 9.35305C0.613278 10.4381 3.04007 13.2553 5.94234 16.1192L2.31883 20.2809L4.61338 22.471L10.4571 20.2154C14.8531 23.9432 20.3387 28.0917 25.3954 31.7646L7.59965 47.221L10.6414 49.2739L36.5424 39.626C38.2969 40.8212 39.5107 41.6374 39.8957 41.8884Z"
                fill="#FFF100"
              />
            </g>
            <defs>
              <clipPath id="clip0_1494_7301">
                <rect
                  width="43.93"
                  height="43.93"
                  fill="white"
                  transform="translate(43.1094) rotate(78.9095)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="right">
        <h5>
          {language == 'ar'
            ? 'نحن نصدر إلى جميع أنحاء العالم'
            : 'We Export To All Over The World '}
        </h5>
        <div className="texts">
          {language == 'ar' ? (
            siteData?.export_world_ar && siteData?.export_world_ar?.length ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: siteData?.export_world_ar,
                }}
              ></p>
            ) : null
          ) : siteData?.export_world_en &&
            siteData?.export_world_en?.length ? (
            <p
              dangerouslySetInnerHTML={{
                __html: siteData?.export_world_en,
              }}
            ></p>
          ) : null}

        </div>
      </div>
    </div>
  );
};

export default ExportBanner;
