import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { base_url } from "../../consts";

// Thunk action to fetch site data asynchronously
export const fetchSiteData = createAsyncThunk(
  "site/fetchSiteData",
  async () => {
    try {
      // Simulate fetching site data from an API
      const response = await Axios({
        url: base_url + `site_info/get_all_for_user`,
        method: "GET",
      });
      return response?.result;
    } catch (error) {
      throw new Error("Error fetching site data:", error);
    }
  }
);

// Create a slice for the site reducer
const siteSlice = createSlice({
  name: "site",
  initialState: {
    siteData:
      localStorage.getItem("ouroubaSiteData") &&
      localStorage.getItem("ouroubaSiteData") != "null" &&
      localStorage.getItem("ouroubaSiteData") != "undefined" &&
      localStorage.getItem("ouroubaSiteData") != undefined
        ? JSON.parse(localStorage.getItem("ouroubaSiteData"))
        : null,
    status: "idle", // Possible states: 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
    // Additional reducers can be added here if needed
    updateSiteData: (state, action) => {
      state.siteData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSiteData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.siteData = action.payload;
        localStorage.setItem(
          "ouroubaSiteData",
          JSON.stringify(action.payload)
        );
      })
      .addCase(fetchSiteData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export actions generated by the site slice
export const { updateSiteData } = siteSlice.actions;

// Export the reducer function
export default siteSlice.reducer;
