export const featuresData=[
  {
    id:1,
    image:require("../../../assets/level.png"),
    title_en:'Level',
    title_ar:'المستوى',
    value_ar:'متوسط',
    value_en:'Meduim',
  },
  {
    id:2,
    image:require("../../../assets/time.png"),
    title_en:'Prep Time',
    title_ar:'وقت التحضير',
    value_ar:'15 دقيقه',
    value_en:'15 mins',
  },
  {
    id:3,
    image:require("../../../assets/time2.png"),
    title_en:'Cooking Time',
    title_ar:'وقت الطبخ',
    value_ar:'45 دقيقه',
    value_en:'45 mins',
  },
  {
    id:4,
    image:require("../../../assets/dish.png"),
    title_en:'Serving',
    title_ar:'خدمة',
    value_ar:'4',
    value_en:'4',
  },
]
