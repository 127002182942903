export const pagesName = [
  {
    name_en: "Home",
    name_ar: "الرئيسية",
    route: "/",
  },
  {
    name_en: "About us ",
    name_ar: "من نحن",
    route: "/about/whoWeAre",
  },
  {
    name_en: "Certifications ",
    name_ar: "الشهادات",
    route: "/about/certifications",
  },
  {
    name_en: "Product Types",
    name_ar: "أصناف المنتجات",
    route: "/about/ProductType",
  },
  {
    name_en: "Brands",
    name_ar: "المنتجات",
    route: "/brands",
  },
  {
    name_en: "Export",
    name_ar: "التصدير",
    route: "/export",
  },
  {
    name_en: "Recipe Details",
    name_ar: "تفاصيل الوصفة",
    route: "/recipe_details",
  },
  {
    name_en: "Recipes",
    name_ar: "الوصفات",
    route: "/Reciepe",
  },
  {
    name_en: "Contact Us",
    name_ar: "اتصل بنا",
    route: "/ContactUs",
  },
  {
    name_en: "Careers ",
    name_ar: "وظائف",
    route: "/career",
  },
  {
    name_en: "Export Catalogue",
    name_ar: "تحميل الكتالوج",
    route: "/ExportCatalog",
  },
];
